import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Theme,
  TableWithDrawer,
  StickyTableTitle,
  NakedButton,
  DataTableNaked,
  AlertService,
  UnawardIcon,
  Column,
  RowAction,
  ColumnFormatters,
  InformationIconSmall,
  Tooltip,
  WandIcon,
} from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import { useMutation } from '@apollo/client';
import accounting from 'accounting';
import OfferListings from '../offer-comparison/offer-listings';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import { weightWithUnit } from '../../utilities/unit-helper';
import {
  TrucklaneDrilldownAwardedOffersZeroState,
  TrucklaneDrilldownOtherOffersZeroState,
  TrucklaneDrilldownSuggestedOffersZeroState,
} from './trucklane-zero-state';
import { UnawardOfferListingsByTruckLane, UnawardOfferListing, AwardSuggestedOfferListings } from '../../graphql/mutations';
import { OfferComparisonStrings } from '../../string-resources';
import { Store } from '../../store';
import { AwardSummaryQuery, trucklanePollingQuery } from '../../graphql/queries';

const styles = {
  row: {
    display: 'flex',
  },
  tableContainer: {
    marginTop: 5,
  },
  tableBorder: {
    marginBottom: 25,
    borderLeft: `1px solid ${Theme.borderColor}`,
    borderTop: `1px solid ${Theme.borderColor}`,
    borderBottom: `1px solid ${Theme.borderColor}`,
    borderRadius: '2px 0px 0px 2px',
  },
  tableFooter: {
    backgroundColor: `${Theme.blue5} !important`,
    boxShadow: `${Theme.borderColor} 0px 1px inset !important`,
  },
  unawardAll: {
    color: `${Theme.red}`,
    background: 'none',
    fill: `${Theme.red}`,
    '&:hover': {
      border: `1px solid ${Theme.red} !important`,
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: `${Theme.red} !important`,
    },
  },
  awardSuggestions: {
    extends: 'unawardAll',
    color: Theme.green,
    fill: Theme.green,
  },
  removePadding: {
    padding: '0 !important',
    marginBottom: 25,
  },
  offerCardBorder: {
    borderTop: `1px solid ${Theme.borderColor}`,
    borderLeft: `1px solid ${Theme.borderColor}`,
    borderRight: `1px solid ${Theme.borderColor}`,
  },
  acceptedOfferRow: {
    padding: '8px 0px 8px 12px',
    backgroundColor: Theme.teal5,
    borderBottom: `1px solid ${Theme.tableBorderColor}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .acceptedOfferTitle': {
      display: 'flex',
      '& svg': {
        fill: Theme.tealDark,
        height: 18,
        width: 18,
        '&:hover': {
          cursor: 'pointer',
        },
      },
      '& p': {
        fontSize: 14,
        fontWeight: 500,
        color: Theme.tealDark,
        margin: 0,
        paddingLeft: 8,
      },
    },
    '& .acceptedMetrics': {
      padding: '8px 24px',
      display: 'flex',
      flexDirection: 'column',
      color: Theme.tealDark,
      fontSize: 14,
      fontWeight: 'bold',
      '& p': {
        fontSize: 12,
        fontWeight: 500,
        textTransform: 'uppercase',
        color: Theme.teal80,
        margin: 0,
      },
    },
  },
  tooltip: {
    maxWidth: 250,
    fontSize: 14,
    textAlign: 'left',
    padding: 8,
  },
};
const useStyles = createUseStyles(styles);

const TrucklaneDrilldownTables = ({
  user,
  history,
  recalculation,
  trucklaneId,
  loading,
  awardedInventory,
  fillupInventory,
  suggestedInventory,
  acceptedInventory,
  aggregateOffer,
}) => {
  const classes = useStyles();
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [awardSuggestedOfferLoading, setAwardSuggestedOfferLoading] = useState(false);
  const [loadingRows, setLoadingRows] = useState([]);
  const [unawardOfferListingsByTruckLane, { loading: unawardOfferListingsByTruckLaneLoading }] = useMutation(UnawardOfferListingsByTruckLane);
  const [unawardOfferListingById, { loading: unawardOfferListingByIdLoading }] = useMutation(UnawardOfferListing);
  const [awardOfferListingById, { loading: awardOfferListingByIdLoading }] = useMutation(AwardSuggestedOfferListings);

  const refetchQueries = [
    {
      query: trucklanePollingQuery,
      variables: {
        pollingDetails: [...Store.trucklaneChannels.values()].map((channel) => ({ origin: channel.originDCId, truckType: channel.truckType })),
      },
    },
    {
      query: AwardSummaryQuery,
    },
  ];

  const columnOverrides = {
    offerListing_quantity: {
      tableFooter: (rows) => {
        rows.reduce((sum, row) => sum + row.offerListing.quantity, 0);
      },
      sortField: 'offerListing.quantity',
    },
    unitCost: {
      tableFooter: (rows) => accounting.formatMoney(rows.reduce((sum, row) => sum + row.unitCost, 0)),
    },
    weight_quantity: {
      tableFooter: (rows) =>
        weightWithUnit(
          rows.reduce((sum, row) => sum + row.unitGrossWeight * row.offerListing.quantity, 0),
          user
        ),
      sortField: (row) => row.unitGrossWeight * row.offerListing.quantity,
    },
    netWeight_quantity: {
      tableFooter: (rows) =>
        weightWithUnit(
          rows.reduce((sum, row) => sum + row.unitNetWeight * row.offerListing.quantity, 0),
          user
        ),
      sortField: (row) => row.unitNetWeight * row.offerListing.quantity,
    },
    cubeAdjustedWeight: {
      tableFooter: (rows) =>
        weightWithUnit(
          rows.reduce((sum, row) => sum + row.cubeAdjustedWeight, 0),
          user
        ),
    },
    offerListing_totalPrice: {
      tableFooter: (rows) => accounting.formatMoney(rows.reduce((sum, row) => sum + row.offerListing.totalPrice, 0)),
      sortField: 'offerListing.totalPrice',
    },
    unitPrice: {
      tableFooter: (rows) => accounting.formatMoney(rows.reduce((sum, row) => sum + row.unitPrice, 0)),
    },
    activeReservePrice: {
      tableFooter: (rows) =>
        accounting.formatMoney(rows.reduce((sum, row) => sum + ColumnFormatters.generic.access.activeReservePrice(null, row), 0)),
    },
    totalPallets: {
      tableFooter: (rows) =>
        accounting.toFixed(
          rows.reduce((sum, row) => sum + row.totalPallets, 0),
          2
        ),
    },
    palletsPerOfferListing: {
      tableFooter: (rows) =>
        accounting.toFixed(
          rows.reduce((sum, row) => sum + (row.offerListing.quantity / row.casesPerPallet || 0), 0),
          2
        ),
    },
    offerListing_discountRate: {
      tableFooter: (rows) => `${accounting.formatNumber(rows.reduce((sum, row) => sum + row.offerListing.discountRate, 0) / rows.length || 0, 2)}%`,
      sortField: 'offerListing.discountRate',
    },
    offerListing_unitPriceRecoveryRate: {
      tableFooter: (rows) =>
        `${accounting.formatNumber(rows.reduce((sum, row) => sum + row.offerListing.unitPriceRecoveryRate, 0) / rows.length || 0, 2)}%`,
      sortField: 'offerListing.unitPriceRecoveryRate',
    },
    offerListing_costRecoveryRate: {
      tableFooter: (rows) =>
        `${accounting.formatNumber(rows.reduce((sum, row) => sum + row.offerListing.costRecoveryRate, 0) / rows.length || 0, 2)}%`,
      sortField: 'offerListing.costRecoveryRate',
    },
    available_cases: {
      tableFooter: (rows) =>
        rows.reduce((sum, row) => {
          const total = sum.split('/');
          const rowCase = ColumnFormatters.generic.access.casesRemaining(null, row).split('/');
          return `${Number(total[0]) + Number(rowCase[0])} / ${Number(total[1]) + Number(rowCase[1])}`;
        }, '0/0'),
      sortField: 'availableQuantity',
    },
    offerListing_suggested_award_quantity: {
      tableFooter: (rows) => accounting.format(rows.reduce((sum, row) => sum + row.offerListing.suggestions?.award?.quantity, 0)),
      sortField: 'offerListing.suggestions.award.quantity',
    },
  };

  const { awardedColumns, fillupColumns, suggestedColumns } = useMemo(() => {
    const defaultColumns = (dataTable, override, competingOffers) => [
      ...getColumnsFromDataTableProfile(dataTable, user.site.dataTableProfiles, override ? columnOverrides : {}, true),
      competingOffers
        ? new Column({
            field: 'competingOffers',
            displayName: '# of Competing Offers',
            defaultSort: false,
            sortable: true,
            formatter: (_, row) => row.competingOffers,
          })
        : [],
    ];
    const columnData = {
      awardedColumns: defaultColumns('Offer Review Buyer Trucklane V2', awardedInventory?.length, true),
      fillupColumns: defaultColumns('Offer Review Buyer Trucklane truckfillup', fillupInventory?.length, true),
      suggestedColumns: defaultColumns('Trucklane Drilldown Suggestions', suggestedInventory?.length, false),
    };
    return columnData;
  }, [user.site.dataTableProfiles, awardedInventory, fillupInventory, suggestedInventory]);

  const removeLoadingArray = (id) => {
    const index = loadingRows.indexOf(id);
    const arrayDup = [...loadingRows];
    if (index > -1) {
      arrayDup.splice(index, 1);
    }
    setLoadingRows(arrayDup);
  };

  const handleUnawardAllClick = () => {
    if (!unawardOfferListingsByTruckLaneLoading) {
      unawardOfferListingsByTruckLane({
        variables: {
          buyerSiteId: aggregateOffer.buyerSiteId,
          truckType: aggregateOffer.truckType,
          logisticsTerm: aggregateOffer.logisticsTerm,
          transactionLocationId: aggregateOffer.transactionLocationId,
          sellerLocationId: aggregateOffer.sellerLocationId,
        },
        refetchQueries,
      })
        .then((unawardResp) => {
          if (unawardResp.data.unawardOfferListingsByTruckLane.errors.length) throw new Error();
          AlertService.alert({ type: 'success', autoDismiss: true, message: <span>All offers from the truck lane have been unawarded.</span> });
        })
        .catch(() => AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>{OfferComparisonStrings.unawardingError}</span> }));
    }
  };

  const handleUnawardOfferClick = (row, e) => {
    if (!unawardOfferListingByIdLoading) {
      e.stopPropagation();
      setLoadingRows([...loadingRows, row._id]);

      unawardOfferListingById({
        variables: {
          id: row.offerListing._id,
        },
        refetchQueries,
      })
        .then(() => {
          setSelectedInventory(null);
          removeLoadingArray(row._id);
          AlertService.alert({ type: 'success', autoDismiss: true, message: <span>The offer has been unawarded.</span> });
        })
        .catch(() => AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>{OfferComparisonStrings.unawardingError}</span> }));
    }
  };

  const handleAwardAllSuggestionsClick = () => {
    let cache = null;
    if (!awardOfferListingByIdLoading && !awardSuggestedOfferLoading) {
      setAwardSuggestedOfferLoading(true);
      awardOfferListingById({
        variables: {
          offerListingIds: suggestedInventory.map((inv) => inv.offerListing._id),
        },
        refetchQueries,
        update: (cacheResult) => {
          cache = cacheResult;
        },
      })
        .then((awardResp) => {
          if (awardResp.data.awardSuggestedOfferListings.errors) throw new Error();
          cache.modify({
            id: cache.identify({ id: trucklaneId, __typename: 'Trucklane' }),
            fields: {
              hasSuggestions() {
                return false;
              },
            },
          });
          AlertService.alert({ type: 'success', autoDismiss: true, message: <span>All suggested offers have been awarded.</span> });
        })
        .catch(() => AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>{OfferComparisonStrings.awardingError}</span> }))
        .finally(() => setAwardSuggestedOfferLoading(false));
    }
  };

  const handleAwardSuggestionsClick = (row, e) => {
    let cache = null;
    if (!awardOfferListingByIdLoading) {
      e.stopPropagation();
      setLoadingRows([...loadingRows, row._id]);

      awardOfferListingById({
        variables: {
          offerListingIds: [row.offerListing._id],
        },
        refetchQueries,
        update: (cacheResult) => {
          cache = cacheResult;
        },
      })
        .then((awardResp) => {
          setSelectedInventory(null);
          removeLoadingArray(row._id);
          if (awardResp.data.awardSuggestedOfferListings.errors) throw new Error();
          if (!suggestedInventory.length) {
            cache.modify({
              id: cache.identify({ id: trucklaneId, __typename: 'Trucklane' }),
              fields: {
                hasSuggestions() {
                  return false;
                },
              },
            });
          }
          AlertService.alert({ type: 'success', autoDismiss: true, message: <span>Suggested offer has been awarded.</span> });
        })
        .catch(() => AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>{OfferComparisonStrings.awardingError}</span> }));
    }
  };

  const awardedRowActions = () => {
    if (awardedInventory?.length)
      return [
        <RowAction
          key={1}
          tooltipText="Unaward Offer"
          loadingTooltipText="Unawarding Offer"
          icon={UnawardIcon}
          loadingRows={loadingRows}
          onClick={(row) => handleUnawardOfferClick.bind(this, row)}
          warning
        />,
      ];
    return [];
  };

  const suggestedRowActions = () => {
    if (suggestedInventory?.length && !recalculation)
      return [
        <RowAction
          key={2}
          tooltipText="Award Suggested Offer"
          loadingTooltipText="Awarding Offer"
          icon={WandIcon}
          loadingRows={loadingRows}
          onClick={(row) => handleAwardSuggestionsClick.bind(this, row)}
        />,
      ];
    return [];
  };

  const handleRowClick = (inventory) => {
    setSelectedInventory(inventory);
  };

  const calculateAcceptedWeight = () => {
    return `${accounting.formatNumber(
      acceptedInventory.reduce((prev, current) => prev + current.unitGrossWeight * current.offerListing.quantity, 0)
    )} lbs`;
  };

  const calculateAcceptedRevenue = () => {
    return `${accounting.formatMoney(acceptedInventory.reduce((prev, current) => prev + current.offerListing.totalPrice, 0))}`;
  };

  return (
    <TableWithDrawer
      className={{ container: classes.tableContainer, table: classes.removePadding }}
      table={
        <div>
          <div className={classes.tableBorder}>
            <StickyTableTitle title="Suggested for award">
              <NakedButton
                onClick={handleAwardAllSuggestionsClick}
                className={classes.awardSuggestions}
                icon={WandIcon}
                loading={awardOfferListingByIdLoading && awardSuggestedOfferLoading}
                disabled={loading || awardOfferListingByIdLoading || awardSuggestedOfferLoading || !suggestedInventory.length || recalculation}
              >
                Award All Suggestions
              </NakedButton>
            </StickyTableTitle>
            <DataTableNaked
              data={!loading ? suggestedInventory : []}
              columns={suggestedColumns}
              loading={loading}
              sticky
              onRowClick={handleRowClick}
              rowActions={suggestedRowActions()}
              className={{ tableFooter: classes.tableFooter }}
              stickyOffset={62}
              stickyFooter={false}
              highlightRowId={selectedInventory?._id}
              noDataMessage={TrucklaneDrilldownSuggestedOffersZeroState(loading, recalculation)}
              cypressTagTable="drilldown-trucklane-suggested-offers-table"
            />
          </div>
          <div className={classes.tableBorder}>
            <StickyTableTitle title={'Currently on this truck lane'}>
              <NakedButton
                onClick={handleUnawardAllClick}
                className={classes.unawardAll}
                icon={UnawardIcon}
                loading={unawardOfferListingsByTruckLaneLoading}
                disabled={loading || unawardOfferListingsByTruckLaneLoading || !awardedInventory.length}
              >
                Unaward All
              </NakedButton>
            </StickyTableTitle>
            {acceptedInventory?.length > 0 && (
              <div className={classes.acceptedOfferRow}>
                <div className="acceptedOfferTitle">
                  <Tooltip
                    small
                    bottom
                    text={
                      <div className={classes.tooltip}>
                        “Currently on this truck lane” factors in previously accepted inventory. <br />
                        <br /> Accepted inventory is no longer actionable on this page but can be viewed on the history screen.
                      </div>
                    }
                  >
                    <InformationIconSmall />
                  </Tooltip>
                  <p>Accepted Inventory</p>
                </div>
                <div className={classes.row}>
                  <div className="acceptedMetrics" data-testid={'accepted-weight'}>
                    <p>Total Accepted Weight</p>
                    {calculateAcceptedWeight()}
                  </div>
                  <div className="acceptedMetrics" data-testid={'accepted-revenue'}>
                    <p>Total Accepted Revenue</p>
                    {calculateAcceptedRevenue()}
                  </div>
                </div>
              </div>
            )}
            <DataTableNaked
              data={!loading ? awardedInventory : []}
              columns={awardedColumns}
              loading={loading}
              sticky
              onRowClick={handleRowClick}
              rowActions={awardedRowActions()}
              className={{ tableFooter: classes.tableFooter }}
              stickyOffset={62}
              stickyFooter={false}
              highlightRowId={selectedInventory?._id}
              noDataMessage={TrucklaneDrilldownAwardedOffersZeroState(loading, acceptedInventory.length > 0, history)}
              cypressTagTable="drilldown-trucklane-awarded-offers-table"
            />
          </div>
          <div className={classes.tableBorder}>
            <StickyTableTitle title="Other offers for this truck lane" />
            <DataTableNaked
              data={!loading ? fillupInventory : []}
              columns={fillupColumns}
              loading={loading}
              sticky
              onRowClick={handleRowClick}
              className={{ tableFooter: classes.tableFooter }}
              stickyOffset={62}
              stickyFooter={false}
              highlightRowId={selectedInventory?._id}
              noDataMessage={TrucklaneDrilldownOtherOffersZeroState(loading)}
              cypressTagTable="drilldown-trucklane-other-offers-table"
            />
          </div>
        </div>
      }
      cardList={
        <div className={classes.offerCardBorder}>
          <OfferListings
            user={user}
            inventoryId={selectedInventory?.id}
            description={selectedInventory?.description}
            trucklaneChannel={Store.trucklaneChannels.get(`${trucklaneId.split(':')[0]}-${trucklaneId.split(':')[2]}`)}
            showSuggestions
            recalculationRequired={recalculation}
          />
        </div>
      }
    />
  );
};

TrucklaneDrilldownTables.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  recalculation: PropTypes.bool,
  trucklaneId: PropTypes.string,
  loading: PropTypes.bool,
  awardedInventory: PropTypes.array,
  fillupInventory: PropTypes.array,
  suggestedInventory: PropTypes.array,
  acceptedInventory: PropTypes.array,
  aggregateOffer: PropTypes.object,
};

export default TrucklaneDrilldownTables;
